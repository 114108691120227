import React, { useState } from 'react'
import iPhone from '../../assent/img/Free_iPhone.png'
import gpay from '../../assent/img/gpay.png'
import bool from '../../assent/img/bool.png'
import apstore from '../../assent/img/apstore.png'
import yoo from '../../assent/img/yoo.png'
import HotNew from '../../assent/img/HotNew.png'
import goup from '../../assent/img/goup.png'
import mobile from '../../assent/img/mobile.png'
import qrcode from '../../assent/img/qr-code.png';
import eran from '../../assent/img/eran.png';
import eran2 from '../../assent/img/eran2.png';
import eran1 from '../../assent/img/eran1.png';
import about from '../../assent/img/about.png';
import '../Homepage/Homepage.css'
function Homepage() {
    const [isActive, setIsActive] = useState(false);

  const toggleClass = event => {
    // 👇️ toggle isActive state on click
    setIsActive(!isActive);
  };
    return (
        <div>
            <section className='Banner_section'>
                <div className="container-fluid custam-container">
                    <div className="tab-content" id="pills-tabContent-data">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='row'>
                                <div className='col-lg-7 col-md-12'>
                                    <div className='banner_text'>
                                        <h1>Exceed is reshaping the entertainment industry  and putting control  <br />
                                 in the hands of artists and fans.</h1>
                                        {/* <p>Exceed is reshaping the entertainment industry and putting control in the hands of artists and fans.</p> */}

                                        <p>With Exceed you can own stock in the income streams of your favorite artists and share in their income;
                                            earning money alongside them. This way, when your favorite artist makes money, so do you.</p>

                                        <p>Likes, follows and streams are passive. Exceed lets everyone have “skin in the game” creating real
                                            engagement. Time to raise the stakes.Turn your playlist into your portfolio and become more than fan.
                                        </p>
                                        <button className='btn Explore'>Explore Our Offerings</button>
                                        <div className='Link_btn'>
                                            <a href='#'><img src={gpay} className='img-fluid' /></a>
                                            <a href='#'><img src={apstore} className='img-fluid' /></a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-5 col-md-12'>
                                    <div className='Img_benner'>
                                        <img src={iPhone} className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <h1 className='imagin_text'>Imagining An Entertainment Industry<br /> Owned By Artists And Their Fans</h1>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div class="card eran_card">
                                        <div class="card-body">
                                            <img src={eran} className='img-fluid' />
                                            <h5 class="card-title">Earn Royalties
                                                Alongside Artists</h5>
                                            <p class="card-text">Fans invest in talent projects and iconic IP by
                                                purchasing TalentShares, offering potential for financial returns based on artist performance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="card eran_card">
                                        <div class="card-body">
                                            <img src={eran2} className='img-fluid' />
                                            <h5 class="card-title">Unlock Exclusive Access And Extras</h5>
                                            <p class="card-text">Fans receive exclusive rewards, content, and benefits from the artists they invest in.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div class="card eran_card">
                                        <div class="card-body">
                                            <img src={eran1} className='img-fluid' />
                                            <h5 class="card-title">Peace Of Mind  Investing</h5>
                                            <p class="card-text">Only SEC qualified offerings, and trading
                                                on an SEC/FINRA  compliant platform*.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className='row'>
                                <div className='col-lg-7 col-md-12'>
                                    <div className='banner_text'>
                                        <h1>Lil Durk To Offer Fans Shares
                                            Of Royalties From Next Song,
                                            “bedtime”</h1>
                                        <button className='btn Explore'>Read More</button>
                                    </div>
                                </div>

                                <div className='col-lg-5 col-md-12'>
                                    <div className='Img_benner'>
                                        <img src={about} className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='Tab_menu'>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a href='#pills-tabContent-data' className={isActive ? 'nav-link' : 'nav-link active'} onClick={toggleClass}   type="button"   >01  About Us</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a  href='#pills-tabContent'className={isActive ? 'nav-link' : 'nav-link'} onClick={toggleClass} >How It Works</a>
                            </li>
                            <li className="nav-item" role="presentation">
                            <a href='#get-btn' className={isActive ?  'nav-link' : 'nav-link '} onClick={toggleClass} >Get Started</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='tabs_section'>
                <div className='container-fluid custam-container'>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='row'>
                                <div className='col-lg-3 col-md-6'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">01</h1>
                                            <h2 className="card-subtitle">Artist is onboarded </h2>
                                            <p className="card-text">Exceed packages a percentage of the Artist’s TRI (Talent Related Income) to be made available as an Investment Offering. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">02</h1>
                                            <h2 className="card-subtitle">Investment Offering is filed with the SEC</h2>
                                            <p className="card-text">When an Artist creates an offering on the Exceed platform they are doing so via Regulation A. Regulation A is an SEC compliant crowdfunding mechanism that allows the general public to invest in the revenue streams of a qualified company, in this case an Artist’s revenue streams. There are many financial, legal and regulatory compliance hurdles and disclosure requirements, all of which must satisfy the SEC before any Regulation A offerings can go live and must continue to be satisfied once the shares have been issued - this is what Exceed is here to handle.
</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">03</h1>
                                            <h2 className="card-subtitle">Fans are able to invest and buy shares in the Investment Offering</h2>
                                            <p className="card-text">When the Investment Offering goes live fans and investors are able to invest and buy shares in a newly made LLC that houses the revenue streams included in the TRI agreement of that Artist.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">04</h1>
                                            <h2 className="card-subtitle">Fans get paid when the artist does</h2>
                                            <p className="card-text">WWhen the Artist gets paid, the fans and investors receive dividends directly through the Exceed app. This means, whenever that Artist makes money, so do you. </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">05</h1>
                                            <h2 className="card-subtitle">Onboard artist</h2>
                                            <p className="card-text">Exceed packages a percentage of TRI (Talent Related Income) from the agreed upon revenue streams into a contract with the Talent making the investment and potential financial returns available to fans/investor</p>

                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">06</h1>
                                            <h2 className="card-subtitle">File offering with the SEC</h2>
                                            <p className="card-text">When an artist creates an offering on the Exceed platform they are doing so via Regulation A. Regulation A is an SEC compliant crowdfunding mechanism that allows the general public to own invest in the revenue streams of a qualified company, in this case an Artists revenue streams. It is considered a “mini IPO” and as such there are many financial, legal and regulatory compliance hurdles and disclosure requirements, all of which must satisfy the SEC before any Reg A offerings can go live and must continue to be satisfied once the shares have been issued - this is what Exceed is here to handle</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">07</h1>
                                            <h2 className="card-subtitle">Allow fans to invest and buy shares in the offering</h2>
                                            <p className="card-text">When the Investment Offering goes live fans and investors are able to invest and buy shares in a newly made LLC that houses the revenue streams included in the TRI agreement of that artist or talent</p>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">08</h1>
                                            <h2 className="card-subtitle">Investors get paid when the artist does</h2>
                                            <p className="card-text">When the artist gets paid, the investors receive dividends directly through the Exceed app. This means, whenever that artist makes money, so do you.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">09</h1>
                                            <h2 className="card-subtitle">Onboard artist</h2>
                                            <p className="card-text">Exceed packages a percentage of TRI (Talent Related Income) from the agreed upon revenue streams into a contract with the Talent making the investment and potential financial returns available to fans/investor</p>

                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">10</h1>
                                            <h2 className="card-subtitle">File offering with the SEC</h2>
                                            <p className="card-text">When an artist creates an offering on the Exceed platform they are doing so via Regulation A. Regulation A is an SEC compliant crowdfunding mechanism that allows the general public to own invest in the revenue streams of a qualified company, in this case an Artists revenue streams. It is considered a “mini IPO” and as such there are many financial, legal and regulatory compliance hurdles and disclosure requirements, all of which must satisfy the SEC before any Reg A offerings can go live and must continue to be satisfied once the shares have been issued - this is what Exceed is here to handle</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">11</h1>
                                            <h2 className="card-subtitle">Allow fans to invest and buy shares in the offering</h2>
                                            <p className="card-text">When the Investment Offering goes live fans and investors are able to invest and buy shares in a newly made LLC that houses the revenue streams included in the TRI agreement of that artist or talent</p>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card" >
                                        <div className="card-body">
                                            <h1 className="card-title">12</h1>
                                            <h2 className="card-subtitle">Investors get paid when the artist does</h2>
                                            <p className="card-text">When the artist gets paid, the investors receive dividends directly through the Exceed app. This means, whenever that artist makes money, so do you.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='tabs_img_list'>
                        <ul className='img_list'>
                            <li><img src={bool} className='img-fluid' /></li>
                            <li><img src={yoo} className='img-fluid' /></li>
                            <li><img src={HotNew} className='img-fluid' /></li>
                        </ul>
                        <div className='tab_bg'>
                            <img src={goup} className='tab_im_list img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className='Questions'>
                <div className="container-fluid custam-container">
                    <div className='Questions_heading'>
                        <h1>Have Questions?<br /> We Got You.</h1>
                        <button className='btn'>Visit our help center</button>
                    </div>
                    <div className='row'>
                        <div className='TalentShares'>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Earn Royalties Alongside Artists
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What payment methods can I use to buy TalentShares?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How does investing in Talent work?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading21">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                            What is meant by "Talent" as an Asset Class?
                                        </button>
                                    </h2>
                                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading22">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                            What are TalentShares?
                                        </button>
                                    </h2>
                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading23">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                                            When I invest, what do I get?
                                        </button>
                                    </h2>
                                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfor">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefor" aria-expanded="false" aria-controls="collapsefor">
                                            How do I make money on my TalentShares?
                                        </button>
                                    </h2>
                                    <div id="collapsefor" className="accordion-collapse collapse" aria-labelledby="headingfor" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading24">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                                            How do I trade my TalentShares?
                                        </button>
                                    </h2>
                                    <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                            talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                            merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                            part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>


                                <div className='srcond_div'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading11">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                                What does AML and KYC approval mean?
                                            </button>
                                        </h2>
                                        <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading12">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                How does my investment support the Talent?
                                            </button>
                                        </h2>
                                        <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading13">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                                What is “Testing the Waters”?
                                            </button>
                                        </h2>
                                        <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading14">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                What is a Regulation A+ Offering?
                                            </button>
                                        </h2>
                                        <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading16">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                                What are music royalties?
                                            </button>
                                        </h2>
                                        <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading15">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                                Are there risks to these investments?
                                            </button>
                                        </h2>
                                        <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.

                                                Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other
                                                talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales,
                                                merchandise, advertising & sponsorship, and a variety of other talent income sources.

                                                Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become
                                                part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className='Get-Started'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='Get_text'>
                                <h1>Get Started on the Exceed</h1>
                                <p>Subscribe our Newsletter</p>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Enter your Email ID" aria-label="Enter your Email ID" aria-describedby="button-addon2" />
                                    <button class="btn" type="button" id="button-addon2">Don't Miss A Beat</button>
                                </div>
                                <h6>Download the app and start investing in your favorite artists!</h6>
                                <div className='Link_btn'>
                                    <a href='#'><img src={apstore} className='img-fluid' /></a>
                                    <a href='#'><img src={gpay} className='img-fluid' /></a>
                                    <a href='#'><img src={qrcode} className='img-fluid' /></a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='get_img'>
                                <img src={mobile} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </div>
    )
}

export default Homepage