import React, { useLayoutEffect } from 'react'
import footerlogo from '../../assent/img/footerlogo.png'
import apstore from '../../assent/img/apstore.png'
import gpay from '../../assent/img/gpay.png'
import qrcode from '../../assent/img/qr-code.png';
import mobile from '../../assent/img/mobile.png'
import { Link, useLocation } from 'react-router-dom';

export default function Footer() {
    const location = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <div>
            <section className='Get-Started Artist_get' id='get-btn'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='Get_text'>
                                <h1>GET STARTED ON THE EXCEED APP</h1>
                                <p>Subscribe to  our newsletter </p>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Enter your Email ID" aria-label="Enter your Email ID" aria-describedby="button-addon2" />
                                    <button class="btn" type="button" id="button-addon2">Don't Miss A Beat</button>
                                </div>
                                <h6>Download the app and start investing in your favorite artists!</h6>
                                <div className='Link_btn'>
                                    <a href='#'><img src={apstore} className='img-fluid' /></a>
                                    <a href='#'><img src={gpay} className='img-fluid' /></a>
                                    <a href='#'><img src={qrcode} className='img-fluid' /></a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='get_img'>
                                <img src={mobile} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='footer_section'>
                <div className="container-fluid custam-container">
                    <div className='row align-items-center'>
                        <div className='col-md-3'>
                            <div className='footer_log'><Link to='/'><img src={footerlogo} className='img-fluid' /></Link></div>
                        </div>
                        <div className='col-md-9'>
                            <ul className='footer_list'>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/'>About us</Link></li>
                                <li><a href='#pills-tabContent'>How it works</a></li>
                                <li><a href='#get-btn'>Get started </a></li>
                                <li><Link to='/Faq' >Faq</Link></li>
                                <li><a href='#'>Press</a></li>
                                <li><ul className='footer_list_icon'>
                                    <li><a href='#'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                                        <path d="M5.28765 12.8101C10.5071 14.7894 14.9488 15.1749 19.9822 12.0941C19.9453 12.1479 18.9496 13.5812 16.3313 14.3158C16.8845 15.0504 17.6405 15.8925 17.6405 15.8925C19.3184 15.8925 20.9594 15.4266 22.3792 14.5308C23.4855 13.8141 24.1124 12.5778 23.9833 11.2878C23.762 9.08399 23.2273 6.93394 22.3976 4.87351C21.3466 2.18596 18.8205 0.322553 15.8888 0.0537767C15.6307 0.0358511 15.4463 0.0358513 15.3357 0.0358513L15.0407 0.3225C18.4149 1.2542 20.0929 2.70548 20.1297 2.75925C14.9484 0.215 8.82688 0.179202 3.60873 2.65176C3.60873 2.65176 5.26821 1.05713 8.93745 0.215L8.7162 0C8.32897 0 7.96019 0.035851 7.57302 0.0895744C4.86254 0.5375 2.59463 2.34713 1.61737 4.83761C0.769182 6.98766 0.21604 9.24521 0.0132303 11.5386C-0.0973981 12.757 0.492638 13.9574 1.52519 14.6562C2.88962 15.5341 4.51221 16 6.15324 16C6.15324 16 6.81701 15.1579 7.48084 14.4054C4.99162 13.6887 3.97751 12.2553 3.95907 12.2015L4.42753 12.434C4.70757 12.573 4.99468 12.699 5.28765 12.8101ZM8.1815 11.252C6.98298 11.2161 6.04261 10.2307 6.07951 9.04814C6.1164 7.93729 7.03832 7.04144 8.1815 7.00559C9.38002 7.04144 10.3204 8.02686 10.2835 9.20941C10.2282 10.3203 9.32468 11.2162 8.1815 11.252ZM15.7044 11.252C14.5059 11.2161 13.5655 10.2307 13.6024 9.04814C13.6393 7.93729 14.5612 7.04144 15.7044 7.00559C16.9029 7.04144 17.8433 8.02686 17.8064 9.20941C17.7696 10.3203 16.8476 11.2162 15.7044 11.252Z" fill="white" />
                                    </svg></a></li>
                                    <li><a href='#'> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                        <path d="M20 2.80741C19.2132 3.14584 18.4262 3.35719 17.6397 3.44209C18.528 2.90911 19.1289 2.15614 19.4419 1.18325C18.6294 1.66538 17.7624 1.99524 16.8403 2.17299C16.028 1.31006 15.0299 0.878418 13.8452 0.878418C12.7116 0.878418 11.7451 1.2781 10.9453 2.07765C10.146 2.87715 9.74624 3.84364 9.74624 4.97726C9.74624 5.28178 9.78002 5.59492 9.84776 5.91638C8.17258 5.83179 6.60113 5.41091 5.13317 4.65372C3.66529 3.89645 2.41953 2.88755 1.39587 1.62702C1.02361 2.2614 0.837415 2.95102 0.837415 3.69549C0.837415 4.3977 1.00236 5.04923 1.33249 5.64991C1.66238 6.2505 2.10665 6.7369 2.66506 7.10921C2.00504 7.08378 1.38747 6.9103 0.812257 6.5888V6.63956C0.812257 7.62948 1.12318 8.49859 1.74493 9.24755C2.36676 9.99642 3.15151 10.4678 4.09898 10.6625C3.74361 10.7554 3.38398 10.802 3.02025 10.802C2.78334 10.802 2.52527 10.7809 2.24613 10.7388C2.50838 11.5593 2.99065 12.2338 3.69276 12.7628C4.39501 13.2916 5.19029 13.5641 6.07864 13.5815C4.58969 14.7488 2.89344 15.3325 0.989874 15.3325C0.626017 15.3325 0.296162 15.3159 0 15.2818C1.90361 16.5084 4.00172 17.1217 6.29448 17.1217C7.74965 17.1217 9.116 16.8915 10.3931 16.4303C11.6708 15.9694 12.7621 15.3516 13.6674 14.5774C14.5725 13.8033 15.3531 12.9128 16.0087 11.9061C16.6643 10.8993 17.1527 9.84823 17.4745 8.75266C17.7959 7.65686 17.9567 6.55937 17.9567 5.45936C17.9567 5.22244 17.9524 5.04487 17.9439 4.92637C18.7479 4.34289 19.4331 3.63633 20 2.80741Z" fill="black" />
                                    </svg></a></li>
                                    <li><a href='#'> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M13.75 0H6.25C2.79875 0 0 2.79875 0 6.25V13.75C0 17.2012 2.79875 20 6.25 20H13.75C17.2012 20 20 17.2012 20 13.75V6.25C20 2.79875 17.2012 0 13.75 0ZM18.125 13.75C18.125 16.1625 16.1625 18.125 13.75 18.125H6.25C3.8375 18.125 1.875 16.1625 1.875 13.75V6.25C1.875 3.8375 3.8375 1.875 6.25 1.875H13.75C16.1625 1.875 18.125 3.8375 18.125 6.25V13.75Z" fill="black" />
                                        <path d="M10 5C7.23875 5 5 7.23875 5 10C5 12.7613 7.23875 15 10 15C12.7613 15 15 12.7613 15 10C15 7.23875 12.7613 5 10 5ZM10 13.125C8.2775 13.125 6.875 11.7225 6.875 10C6.875 8.27625 8.2775 6.875 10 6.875C11.7225 6.875 13.125 8.27625 13.125 10C13.125 11.7225 11.7225 13.125 10 13.125Z" fill="black" />
                                        <path d="M15.375 5.29148C15.7429 5.29148 16.0412 4.99319 16.0412 4.62523C16.0412 4.25727 15.7429 3.95898 15.375 3.95898C15.007 3.95898 14.7087 4.25727 14.7087 4.62523C14.7087 4.99319 15.007 5.29148 15.375 5.29148Z" fill="black" />
                                    </svg></a></li>
                                </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr className='line' />
                    <div className='footer_text'>
                        {/* <p>Exceed combines fandom with real financial investment.</p> */}
                        <p>Exceed's Offerings Are Described In Full In Our Offering Circular Which You Can  <a href='https://www.sec.gov/Archives/edgar/data/1956060/000168316823002470/exceed_253g2.htm' target='_blank'>Read Here </a>.If You Want To Invest, Please Email Us At  <a href='investments@exceedtc.com'>investments@exceedtc.com</a>And We'll Be In Touch With Next Steps.</p>

                        <p>Exceed Does Not Give Investment Advice, Endorsement, Analysis Or Recommendations With Respect To Any Securities And/Or Offerings. </p>
                        <p>The Content Of This Site Should Not Be Construed As Financial Advice Nor As An Offer Or Solicitation To Any Person In Any Jurisdiction To Purchase Or Subscribe For Any Investment Or Security.</p>
                        <p>Offers Are Made Exclusively Through Exceed's Official Offering Documents And Solely To Eligible Investors In Jurisdictions Where Such Offer, Distribution, Publication, Availability Or Use Would Be Permitted Under Applicable Laws.
                        </p>
                        <p>All Information In This Site Is Provided “As Is”, Neither Exceed Nor Any Of Its Affiliates, Officers, Directors, Agents, Employees And/Or Representatives Make Any Warranty, Express Or Implied, Of Any Kind, Related To The Adequacy, Accuracy Or Complete
                            ness Of That Information Or The Use Of It.</p>
                        <p>Any Decision To Invest Should Be Made Only On The Basis Of The Relevant Documentation For Each Investment And The Risks Detailed Therein.</p>
                    </div>
                    <div className='text_list'>
                        <p>© 2023 EXCEED TALENT CAPITAL LTD. ALL RIGHTS RESERVED </p>
                        <div><a href='https://exceedtc.com/privacy' target='_blank'>PRIVACY POLICY </a><a href='https://exceedtc.com/terms' target='_blank'> Terms of use </a></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
