import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import contact1 from '../../assent/img/contact14.png'
import contact2 from '../../assent/img/contact13.png'
import contact3 from '../../assent/img/contact12.png'
import contact4 from '../../assent/img/contact14.png'
export default function Contact() {
    const { pathname } = useLocation();
    return (
        <div>

            <section className='Contact_section'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='Contact_text'>
                                <h1>Contact us</h1>
                                <p>Please contact us using thr information below, to locate contacts in the
                                    business office closest to you, visit our office website.</p>
                            </div>
                        </div>
                        <div className='col-md-7'>
                        </div>
                    </div>

                    <div className='back_btn'>
                        <Link to="/" className={(pathname === '/') ? 'active Link_page' : 'Link_page'} >Home</Link><span>{">"}</span><Link to="" className={(pathname === '/Contact') ? 'active Link_page' : 'Link_page'} >Contact us </Link>
                    </div>
                </div>
            </section>

            <section className='Office_section'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='office_text'>
                                <div className='dummy'> <img src={contact1} className='img-fluid' /></div>
                                <div className='office_text_div'>
                                    <h1>Office</h1>
                                    <p>18978 Woodside St.<br />
                                        Goose creek, sc 29445</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='office_text'>
                                <div className='dummy'> <img src={contact2} className='img-fluid' /></div>
                                <div className='office_text_div'>
                                    <h1>Call us</h1>
                                    <p>(440)679-49842.<br />
                                     (423)646-4864</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='office_text'>
                                <div className='dummy'> <img src={contact3} className='img-fluid' /></div>
                                <div className='office_text_div'>
                                    <h1>Send us</h1>
                                    <p>contact@exceedtc.com <br />support@exceedtc.com</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='office_text'>
                                <div className='dummy'> <img src={contact4} className='img-fluid' /></div>
                                <div className='office_text_div'>
                                    <h1>Fax</h1>
                                    <p>+1-212-9876543<br />
                                        +44-208-1234567</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='TouchUs'>
                <div className='container-fluid custam-container'>
                    <div className='touch_heading'>
                        <h1>Get in touch with us</h1>
                        <p>Whether you have a question about featires, trials, pricing, need a
                            demo, or anythings else, our team is realy to answer</p>
                        <form className="row g-3">
                            <div className="col-md-6">
                                <input type="text" className="form-control" id="Firstname" placeholder='First name' />
                            </div>
                            <div className="col-md-6">
                                <input type="text" className="form-control" id="LastName" placeholder='Last Name' />
                            </div>
                            <div className="col-12">
                                <input type="text" className="form-control" id="EmailID" placeholder="Email ID" />
                            </div>
                            <div className="col-12">

                                <textarea type="text" className="form-control" placeholder='Message... ' rows="5" />
                            </div>

                            <button className='contact-submit'>Submit now</button>
                        </form>
                    </div>

                </div>
            </section>
            <section className='map'>
                <div className='map_div'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d429270.70304945135!2d-97.28931895!3d32.80081295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e6e122dc807ad%3A0xa4af8bf8dd69acbd!2sFort%20Worth%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1682424716722!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </div>
    )
}
