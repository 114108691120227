import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function Faq() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return (
        <div>
            <section className='Questions'>
                <div className="container-fluid custam-container">
                    <div className='Questions_heading'>
                        <h1>Have questions? we got you.</h1>
                        <button className='btn'>Visit our help center</button>
                    </div>
                    <div className='row'>
                        <div className='TalentShares'>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is exceed?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love.
                                            Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales, merchandise, advertising & sponsorship, and a variety of other talent income sources.
                                            Investing is just the beginning. The relationship between Talents and their Fans is a powerful dynamic. Exceed’s in-app fan engagement tools allow fans to become part of the success of their idols not only through financial benefits but also special exclusive rewards.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How do i invest?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            It's easy. Carefully read our Offering Circular which available here, email us at investments@exceedtc.com and we'll be in touch with next steps!
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What payment methods can i use to buy talentshares?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Currently the Exceed App accepts ACH payments, Credit and Debit Cards
                                            Other payment methods - coming soon.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading21">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                            How does investing in talent work?
                                        </button>
                                    </h2>
                                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Cross over into the exclusive world of investing in Talent with Exceed
                                            Investing in talent was traditionally available to a privileged few such as media companies, private equity firms, institutional investors, and high net worth individuals. There are few practical ways for a regular fan and investor to invest in the talent they love or the iconic works of those talents. A fan has traditionally been a spectator who could only dream of being involved with talent, however Exceed has changed the dynamic by enabling fans to invest in talent.
                                            Talent Related Assets (TRA)
                                            can be derived from multiple sources (examples: Musical Artists and Creators):
                                            Musical Artists
                                            Live performance & Touring
                                            Recorded music distribution - physical sales
                                            Streaming income
                                            Merchandise
                                            Multiple royalty streams from previously created works
                                            Publishing royalties
                                            Performance royalties (digital)
                                            Creators - Online content creators such as YouTubers and influencers
                                            Subscription income
                                            Sponsorship/Promotion
                                            Advertising
                                            Streaming income
                                            Donations/fan engagement
                                            Based on the commercial arrangement with the Talent, Exceed, packages a percentage of TRA from the negotiated sources into a contract with the Talent and makes the investment and potential financial returns available to fans/investors. This is referred to in our Offering Document as a TRAAA (Talent Related Asset Assignment Agreement). This TRAAA may be, for example, limited to the streaming revenues related to one song from a musical artist or it may be more encompassing across a range of activities and assets.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading22">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                            What is meant by "Talent" as an asset class?
                                        </button>
                                    </h2>
                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            As Fans, we enjoy the special talents of popular musical artists, YouTubers, and athletes who affect billions of people around the world.
                                            They also collectively generate a significant amount of income. In 2021, Musical Artists, Creators and Athletes generated over $500bn of income, an increase of 14% from 2020. (Source: Note: Based on 2021 US Bureau of Labor and Statistics International Report 2021, State of the Creator Report 2021, and Deloitte Sports Income Report 2021, the types of individuals that are considered by Exceed as being part of its potential target assets (Music & Entertainment, Sports, Online Creators, and Chefs).
                                            Investing in talent was traditionally available to a privileged few such as media companies, private equity firms, institutional investors, and high net worth individuals. There are few practical ways for a regular fan and investor to invest in the talent they love or the iconic works of those talents. A fan has traditionally been a spectator who could only dream of being involved with talent, however Exceed has changed the dynamic by enabling fans to invest in talent
                                            There are many talent marketplaces, each offering different methods and levels of participation for fans and investors. Exceed has chosen to take a rigorous regulatory approach to creating its talent investment platform and, eventually, its exchange.
                                            We hold sacred the need to protect the Fans and Talents on the Exceed Platform, and to provide as safe an investment environment as we reasonably can. Working within the applicable financial regulatory framework, we see financial and regulatory compliance, as well as a deep sense of commercial integrity and fiduciary duty to Fans and Talents, as cornerstones of the Exceed Platform.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading23">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                                            What are talentshares?
                                        </button>
                                    </h2>
                                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            TalentShares are a new way for Fans to buy a stake in the success of Talent they love and share in their success.
                                            TalentShares are shares in a series LLC that will be offered to the general public under Regulation A+ of the U.S. Securities and Exchange Commission. In the run-up to each offering, Exceed’s market experts work with the Talent and their representation to evaluate and determine the number of TalentShares to be offered, and at what price.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfor">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefor" aria-expanded="false" aria-controls="collapsefor">
                                            When I invest, what do I get?
                                        </button>
                                    </h2>
                                    <div id="collapsefor" className="accordion-collapse collapse" aria-labelledby="headingfor" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Investors get TalentShares that represent a proportional share of the future value generated by the Talent or Talent related projects. In addition, as a TalentShare holder, you will receive exclusive content and updates on your investment as well as potentially be eligible for special benefits related to the Talent and their projects.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading24">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                                            How do I make money on my talentshares?
                                        </button>
                                    </h2>
                                    <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Fans may be able to benefit financially from their TalentShares in two ways:
                                            Trading. You may make (or lose) money by buying or selling TalentShares in the market at a different price to the one that you purchased it for.
                                            Income. Talent Shareholders have the potential of benefiting from quarterly financial distributions, based on the performance of the Talent. Any form of income or capital distribution, is not guaranteed, and it is possible that the TalentShares you own will never distribute any money at all.
                                        </div>
                                    </div>
                                </div>


                                <div className='srcond_div'>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading11">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                                How do I trade my talentshares?
                                            </button>
                                        </h2>
                                        <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                TalentShares are, theoretically, immediately tradable following purchase. There are no mandatory holding periods, and you can sell your TalentShares privately as soon as you take possession of them. However trading in TalentShares can only take place on a suitably licensed Alternative Trading Platform. Exceed intends to offer this functionality in the near future via its app, although please note that there is no guarantee that any such trading platform will be made available to TalentShare holders, and even if it is, there is no guarantee of liquidity, or of you finding a willing buyer or seller for your TalentShares.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading12">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                How often will I receive distributions?
                                            </button>
                                        </h2>
                                        <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                If and when your TalentShares start generating income, we intend to make quarterly distributions to Talent Shareholders.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading13">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                                What does aml and kyc approval mean?
                                            </button>
                                        </h2>
                                        <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                AML or “anti-money laundering” refers to the steps we must take to ensure that investment capital comes from legitimate sources, and not from the proceeds of crime or other illicit activity.
                                                KYC or “Know Your Customer” refers to the checks that we must perform to ensure our investors are who they say they are and do not pose a risk to the business (for example, if they are engaged in act of fraud or impersonation, or may otherwise pose a risk to our business, our Talents and our investors). KYC and AML are usually bundled together, but actually set out to achieve two different things, with AML being a subset of KYC.
                                                Exceed and its partners are required to treat regulatory compliance with the utmost seriousness, as such, we are obliged to ensure that all our investors meet the required standards before we can accept their money.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading14">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                How does my investment support the talent?
                                            </button>
                                        </h2>
                                        <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                This depends on the type of investment that is being offered. Our investments range from investing in a Talent’s project before it has been completed (new projects) to Talent creations that may have been completed years earlier (historical IP).
                                                If a Fan invests in a Talent’s new project, the funds are typically utilized by the Talent to complete the project and bring it to market. In this case, the funds go directly to assisting the Talent in advancing their career. Once the Fan has invested, it is then through Exceed’s fan engagement app that they can promote the Talent and be part of the future of the project in which they invested.
                                                If a Fan invests in a completed project (historical IP), then the proceeds may go to the Talent to allow them to create new projects based on the investment they received from historical ones. In this case, the fan can also promote the Talent and be part of their continuing journey using our fan engagement app.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading16">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                                What is “testing the waters”?
                                            </button>
                                        </h2>
                                        <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Testing the Waters was introduced by the SEC in 2012 as a method of allowing companies to gauge interest in their potential public offerings, without risking their own resources on expensive and time-consuming offering processes, and without investors risking their money on offerings that may never come to fruition. In short, Testing the Waters is a mechanism that allows Exceed to gauge public interest in its TalentShare offerings without Fans committing to purchasing anything. A copy of the most recent version of Exceed's Offering Statement may be obtained by emailing contact@exceedtc.com, or accessed online here.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading15">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                                What is a regulation a+ offering?
                                            </button>
                                        </h2>
                                        <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Regulation A is a type of crowdfunding mechanism that allows members of the general public (and not just accredited investors) to purchase shares (what we call “TalentShares”) from qualifying companies. It is considered a “mini IPO” and as such there are many financial, legal and regulatory compliance hurdles and disclosure requirements, all of which must satisfy the SEC before any TalentShares can be sold, and must continue to be satisfied once the TalentShares have been issued.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading19">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                                I’m a talent, content owner or distributor. What’s in it for me?
                                            </button>
                                        </h2>
                                        <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The exceed platform provides artists, record labels and distributors with mechanisms to help promote their creative works, be it individual songs, albums, historic catalogs or other intellectual property. Exceed’s strategy is built on a flywheel effect. Allowing fans to become investors, they become financially motivated to promote their artists on multiple social media platforms as well as participate in the exceed platform artist promotion programs. The exceed platform creates a true partnership between fan and talent, each benefitting from the potential for greater value.
                                                In addition, by creating a marketplace that attracts and welcomes both world famous artists as well as emerging talent, exceed creates a cross-collaboration and promotion strategy that will further the artists career. Exceed also offers more tools and services to artists who list their music assets on our platform such as:
                                                Data analysis provided to artists on their fan base
                                                Tracking and accounting of rights payments from streaming media
                                                Online promotion tools for social media platforms involving their fans
                                                Cross promotion opportunities with world class artists
                                                Special interactive opportunities between artists and fans to further the “partnership” concept
                                                Monetization of stems (parts of a song)
                                                Contact us to find out more - we’d love to hear from you!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading17">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                                What are music royalties?
                                            </button>
                                        </h2>
                                        <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Music royalties are payments received by rights holders such as songwriters, composers, and recording artists, in exchange for the licensed use of their music. These royalties are paid out by institutions that use the music (from TV channels, radio stations and venues to streaming platforms and beyond), and collected on behalf of rights holders most of the time by Performance Rights Organizations (PROs). In some cases, the user (such as major labels) will pay out the royalties directly to rights holders.
                                                Performance royalties are licensed and collected by PROs. Songwriters and publishers register with these organizations to collect performance royalties due them whenever a song is played in public. This includes the playing of a song on radio (AM/FM, streaming, or satellite), in a concert hall or in a restaurant, and on television shows and commercials. These PROs pay both the publisher and the songwriter for their respective share of the performance royalty that they collect.
                                                In some cases, the offerings that are presented on the Exceed platform go beyond music rights deals and often have a higher income potential. If the offering is related to an associated record label or distributor, the owners shares in such an offering will also potentially receive income from physical sales of albums & CDs and other sources of revenue such as merchandise sales.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading18">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                                Are there risks to these investments?
                                            </button>
                                        </h2>
                                        <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Yes. There is a significant risk that you may lose all or some of the money you invest in TalentShares.
                                                There are many other risks related to an investment in TalentShares, which include, among other things, the Talent’s performance, the Talent’s industry, technological, economic, regulatory and political factors.
                                                Each offering will refer you to an offering document that will include a detailed section describing what we consider to be the main risk factors, and you are strongly encouraged to read the entire document before considering any investment in TalentShares.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
