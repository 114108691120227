import React, { useLayoutEffect } from 'react'
// import '../Artistuser/Doodie.css'
import { Link, useLocation } from 'react-router-dom'
import Slider from 'react-slick';
import u2 from '../../../assent/img/Doodie2.png'
import silde3 from '../../../assent/img/silde21.png'
import silde2 from '../../../assent/img/silde22.png'
import silde1 from '../../../assent/img/silde23.png'
import line from '../../../assent/img/line.png'
import DoodieLo from '../../../assent/img/DoodieLo.png'


export default function Doodie() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
       window.scrollTo(0, 0)
    }, [pathname])
    const settings = {
        dots: false,
        infinite: false,
        navs: true,
        speed: 2000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
              breakpoint: 996,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  navs: false,
                }
              }
        ,]
        // centerMode: true,
        // centerMode: true,
        //         centerMode: true,
        //   centerPadding: '60px',

    };

    return (
        <div>
            <section className='user-Artists'>
                <div className="container-fluid custam-container">
                    <div className='back_btn'>
                        <Link to="/" className={(pathname === '/') ? 'active Link_page' : 'Link_page'} >Home</Link><span>{">"}</span><Link to="" className={(pathname === '/Artist/Doodie') ? 'active Link_page' : 'Link_page'} > Doodie Lo </Link>
                    </div>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='Artist_user_heding'>
                            <h1>Bedtime</h1>
                                <h6> Doodie Lo</h6>
                                {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the
                                    1500s, when an unknown printer took a galley of type and scrambled it to
                                    make a type specimen book. It has survived not only five centuries, but also
                                    the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='Artist_img'>
                        <img src={DoodieLo} className='img-fluid' />
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='total-Artists'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='Total_text'>
                                <h1>$1,800,000</h1>
                                <p>Inital offering marketing cap</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='Total_text'>
                                <h1>$10</h1>
                                <p>Inital offering share price</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='Total_text  last_text'>
                                <h1>30/2023</h1>
                                <p>Intial offering</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='DETAIL_Page'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='Detail_text'>
                                <p>While much of OTF hails from Chicago's South Side, Doodie Lo is a native of Kankakee, Illinois—50 miles south of the Windy City. "I grew up behind the K-Mart, in the Crestview Apartments, better known as 'the bricks,'" shares the 29 year-old-rapper of his small town. "I jumped out into the streets around 12, 13," the former athlete admits. The stakes would become higher during his teens. "I had my first daughter at 17, so I started hustling."</p>
                                <button className='btn_detail'>DETAIL</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='Detail_img'>

                                <img src={u2} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='gallery'>
                <div className="container-fluid custam-container">
                    <h1 className='Heding_Text_gallery'>#Doodie Lo<span>Gallery</span></h1>
                </div>
                <div className='gallery_silder'>
                    <Slider {...settings}>
                        <div className='gall_img'>
                            <img src={silde1} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <img src={silde2} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <img src={silde3} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <img src={silde2} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <img src={silde3} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <img src={silde1} className='img-fluid' />
                        </div>
                    </Slider>

                </div>
                <div className="container-fluid custam-container">
                    <div className='row'>
                    </div>
                </div>
            </section>

            <section className='John_Smith Doodie'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='Bnner_john_text'>
                            <h1>Doodie Lo emits convincing terror whether he’s trading bars with Kodak Black or fellow OTF crew members. Using his husky rasp, he lets loose the most dangerous kind of promise that ties together rage, brotherhood and the OTF lifestyle in compelling fashion.</h1>
                            <p> -Hip Hop DX</p>
                        </div>
                    </div>
                </div>
                {/* <div className='Durk_gallery'>
                    <div className="container-fluid custam-container">
                        <h1 className='Heding_Text_gallery'>#Doodie Lo<span>Timeline</span></h1>
                    </div>
                    <div className='gallery_silder'>
                        <Slider {...settings}>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <p>"Lorem ipsum dolor sit amet, consectetur
                                    dolore magna aliqua. Ut enim ad minim
                                    nostrud exercitation ullamco laboris nisi
                                    ex ea commodo consequat. Duis aute
                                    in reprehenderit</p>
                                <span>2002-05-15</span>
                                <img src={line} className='img-fluid' />
                            </div>
                        </Slider>
                    </div>
                </div> */}
            </section>

            <section className='Financials'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-6'>
                        <div className="financials_text">
                                <h1>‘Bedtime’ by lil<br />
                                    durk & doodie lo
                                    {/* <span>Timeline</span> */}
                                </h1>
                                <p>Talentshare holders will receive shares in an llc owning the rights to 50% of the streaming income generated by "Bedtime" across
                                    Various platforms such as spotify. Apple music, amazon music, youtube music, and more,</p>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                        <div className='Financials_div'>
                                <h1>Opportunity</h1>
                                <ul className='fine_text'>
                                    <li><h5>Funding goal</h5><p>$600,000</p></li>
                                    <li><h5>Minimum investment</h5><p>$90</p></li>
                                    <li><h5>Asset</h5><p>song</p></li>
                                    <li><h5>Income</h5><p>streaming</p></li>
                                    <li><h5>Instument</h5><p>equity</p></li>
                                    <li><h5>Opeaning date</h5><p>april 19, 2023</p></li>
                                    <li><h5>Expected closing date</h5><p>july 19, 2023</p></li>
                                </ul>
                            </div>
{/* 
                            <div className='Financials_div margin_div'>
                                <h1>Asset Specifications</h1>
                                <ul className='fine_text'>
                                    <li><h5>Iaperiam</h5><p>$ 10</p></li>
                                    <li><h5>nostrum exercitationem ullam</h5><p>180,000</p></li>
                                    <li><h5>obtain some advantage</h5><p>$1,800,000</p></li>
                                    <li><h5>voluptas assumenda est</h5><p>Exceed</p></li>
                                    <li><h5>officia deserunt mollitia</h5><p>Exceed</p></li>
                                    <li><h5>when nothing prevents</h5><p>Exceed</p></li>
                                </ul>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
