import React, { useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Slider from 'react-slick';
import DaylinXL3 from '../../../assent/img/DaylinXL3.png'
import silde3 from '../../../assent/img/DaylinXL4.png'
import silde2 from '../../../assent/img/DaylinXL1.png'
import silde1 from '../../../assent/img/DaylinXL2.png'
import line from '../../../assent/img/line.png'
import DaylinXL4 from '../../../assent/img/DaylinXL4.png'
export default function DaylinXL() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
       window.scrollTo(0, 0)
    }, [pathname])

    const settings = {
        dots: false,
        infinite: false,
        navs: true,
        speed: 2000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    navs: false,
                }
            }
            ,]
        // centerMode: true,
        // centerMode: true,
        //         centerMode: true,
        //   centerPadding: '60px',

    };
    return (
        <div>
            <div>
                <section className='user-Artists'>
                    <div className="container-fluid custam-container">
                        <div className='back_btn'>
                            <Link to="/" className={(pathname === '/') ? 'active Link_page' : 'Link_page'} >Home</Link><span>{">"}</span><Link to="" className={(pathname === '/Artist/DaylinXL') ? 'active Link_page' : 'Link_page'} >DaylinXL </Link>
                        </div>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='Artist_user_heding'>
                                    <h1>The Hills Revisited</h1>
                                    <h6>DaylinXL</h6>
                                    {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s, when an unknown printer took a galley of type and scrambled it to
                                        make a type specimen book. It has survived not only five centuries, but also
                                        the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className='Artist_img'>
                                    <img src={DaylinXL4} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='total-Artists'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='Total_text'>
                                    <h1>$1,800,000</h1>
                                    <p>Inital offering marketing cap</p>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='Total_text'>
                                    <h1>$10</h1>
                                    <p>Inital offering share price</p>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='Total_text  last_text'>
                                    <h1>30/2023</h1>
                                    <p>Intial offering</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='DETAIL_Page'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Detail_text'>
                                    <p>DaylinXL is a rising recording artist with a passion for both music and basketball. With a background in the sport, DaylinXL attended Mississippi Valley State on a Division 1 scholarship before pursuing his true calling in music. After relocating to Los Angeles, he joined an artist incubator program run by multi-platinum songwriter and producer Kool Kojack. It was there that he connected with fellow Milwaukee producer Jaron Tsuchiyama, and the two began working on DaylinXL's debut EP, "Before it's 2 Late".  DaylinXL is now focused on recording more music and launching his own fashion brand. His next project, "The Hills Revisited", is slated for release in 2023. Keep an eye out for this rising star.</p>
                                    <button className='btn_detail'>DETAIL</button>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Detail_img'>

                                    <img src={DaylinXL3} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='gallery'>
                    <div className="container-fluid custam-container">
                        <h1 className='Heding_Text_gallery'>#Daylinxl<span>Gallery</span></h1>
                    </div>
                    <div className='gallery_silder'>
                        <Slider {...settings}>
                            <div className='gall_img'>
                                <img src={silde1} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde2} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde3} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde2} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde3} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde1} className='img-fluid' />
                            </div>
                        </Slider>
                    </div>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                        </div>
                    </div>
                </section>

                <section className='John_Smith Daylinxl'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='Bnner_john_text'>
                                <h1>"DaylinXL is a true artistic gem, showcasing a rare talent for combining clever lyricism with beautifully melodic croons that captivate the listener. His ability to effortlessly deliver his craft is a true testament to his dynamic skills as an artist."</h1>
                                <p>  - Kool Kojack;Platinum Songwriter and Producer

</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Durk_gallery'>
                        <div className="container-fluid custam-container">
                            <h1 className='Heding_Text_gallery'>#DaylinXL<span>Timeline</span></h1>
                        </div>
                        <div className='gallery_silder'>
                            <Slider {...settings}>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                                <div className='gall_img'>
                                    <p>"Lorem ipsum dolor sit amet, consectetur
                                        dolore magna aliqua. Ut enim ad minim
                                        nostrud exercitation ullamco laboris nisi
                                        ex ea commodo consequat. Duis aute
                                        in reprehenderit</p>
                                    <span>2002-05-15</span>
                                    <img src={line} className='img-fluid' />
                                </div>
                            </Slider>
                        </div>
                    </div> */}
                </section>

                <section className='Financials'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="financials_text">
                                    <h1>#Daylinxl<span>Timeline</span></h1>
                                    <p>Talentshare holders will receive shares in an llc owning the rights to 30% the streaming income generated by daylinxls next 15 song across various platforms</p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                            <div className='Financials_div'>
                                <h1>Opportunity</h1>
                                <ul className='fine_text'>
                                    <li><h5>Funding goal</h5><p>$4000</p></li>
                                    <li><h5>Minimum investment</h5><p>$15</p></li>
                                    <li><h5>Asset</h5><p>15 song</p></li>
                                    <li><h5>Income</h5><p>streaming</p></li>
                                    <li><h5>Instument</h5><p>equity</p></li>
                                    <li><h5>Opeaning date</h5><p>april 17, 2023</p></li>
                                    <li><h5>Expected closing date</h5><p>july 17, 2023</p></li>
                                </ul>
                            </div>

                                {/* <div className='Financials_div margin_div'>
                                    <h1>Asset Specifications</h1>
                                    <ul className='fine_text'>
                                        <li><h5>Iaperiam</h5><p>$ 10</p></li>
                                        <li><h5>nostrum exercitationem ullam</h5><p>180,000</p></li>
                                        <li><h5>obtain some advantage</h5><p>$1,800,000</p></li>
                                        <li><h5>voluptas assumenda est</h5><p>Exceed</p></li>
                                        <li><h5>officia deserunt mollitia</h5><p>Exceed</p></li>
                                        <li><h5>when nothing prevents</h5><p>Exceed</p></li>
                                    </ul>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}
