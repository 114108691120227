import React from 'react'
import logo from '../../assent/img/ExceedLogo.png'
import { Link, useLocation } from 'react-router-dom'

function Navbar() {
  const { pathname } = useLocation();
  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid custam-container ">
          <Link to='/' className="navbar-brand" href="#"><img src={logo} className='img-fluid' /></Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav align-items-center">
              <li class="nav-item">
                <Link to='/' className={(pathname === '/') ? 'active nav-link' : 'nav-link'}   >Home</Link>
              </li>
              <li class="nav-item">
              <Link to='/' className="nav-link">About us</Link>
              </li>
              {/* <li class="nav-item">
          <Link to='/' class="nav-link"> How it works </Link>
        </li> */}
              <li class="nav-item">
                <Link to='/Artist' className={(pathname === '/Artist') ? 'active nav-link' : 'nav-link'} >Artist</Link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href='#get-btn'>Get started</a>
              </li>
              <li class="nav-item">
              <Link to='/Faq'  className={(pathname === '/Faq') ? 'active nav-link' : 'nav-link'}  >Faq</Link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Press</a>
              </li>
              <li class="nav-item">
              <Link to='/Contact'   ><button type='button' className='btn'> Contact us</button></Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>


    </div>
  )
}

export default Navbar