import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Homepage from './components/Homepage/Homepage';
import Footer from './components/footer/Footer';
import {Route, Routes } from "react-router-dom";
import Artist from './components/Artist/Artist';
import Artistuser from './components/Artist/Artistuser/Artistuser';
import Doodie from './components/Artist/Artistuser/Doodie';
import DaylinXL from './components/Artist/Artistuser/DaylinXL';
import LuhMoody from './components/Artist/Artistuser/LuhMoody';
import BigHavi from './components/Artist/Artistuser/BigHavi';
import AlexEnvy from './components/Artist/Artistuser/AlexEnvy';
import Contact from './components/ContactUs/Contact';
import Faq from './components/Faq/Faq';

function App() {
  return (
    <>
     <Navbar />
     <Routes>
          <Route exact path="/" element={<Homepage/>}/>
          <Route exact path="/Artist" element={<Artist/>}/>
          <Route exact path="/Artist/Artistuser" element={<Artistuser/>}/>
          <Route exact path="/Artist/Doodie" element={< Doodie/>}/>
          <Route exact path="/Artist/DaylinXL" element={<DaylinXL/>}/>
          <Route exact path="/Artist/LuhMoody" element={<LuhMoody/>}/>
          <Route exact path="/Artist/BigHavi" element={<BigHavi/>}/>
          <Route exact path="/Artist/AlexEnvy" element={<AlexEnvy/>}/>
          <Route exact path="/Contact" element={<Contact/>}/>
          <Route exact path="/Faq" element={<Faq/>}/>
        </Routes>
        <Footer />
     </>
  );
}

export default App;
