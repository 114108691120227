import React, { useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Slider from 'react-slick';
import DaylinXL3 from '../../../assent/img/BigHavi3.png'
import silde3 from '../../../assent/img/BigHavi22.png'
import silde2 from '../../../assent/img/BigHavi1.png'
import silde1 from '../../../assent/img/BigHavi2.png'
import line from '../../../assent/img/line.png'
import DaylinXL4 from '../../../assent/img/BigHavi4.png'

export default function BigHavi() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
       window.scrollTo(0, 0)
    }, [pathname])

    const settings = {
        dots: false,
        infinite: false,
        navs: true,
        speed: 2000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    navs: false,
                }
            }
            ,]
        // centerMode: true,
        // centerMode: true,
        //         centerMode: true,
        //   centerPadding: '60px',

    };
  return (
    <div> <div>
    <div>
        <section className='user-Artists'>
            <div className="container-fluid custam-container">
                <div className='back_btn'>
                    <Link to="/" className={(pathname === '/') ? 'active Link_page' : 'Link_page'} >Home</Link><span>{">"}</span><Link to="" className={(pathname === '/Artist/LuhMoody') ? 'active Link_page' : 'Link_page'} >Big Havi
</Link>
                </div>
                <div className='row'>
                    <div className='col-lg-5'>
                        <div className='Artist_user_heding'>
                            <h1>Personal Problems 3 </h1>
                            <h6>Big Havi
</h6>
                            {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book. It has survived not only five centuries, but also
                                the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='Artist_img'>
                            <img src={DaylinXL4} className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='total-Artists'>
            <div className="container-fluid custam-container">
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='Total_text'>
                            <h1>$1,800,000</h1>
                            <p>Inital offering marketing cap</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='Total_text'>
                            <h1>$10</h1>
                            <p>Inital offering share Price</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='Total_text  last_text'>
                            <h1>30/2023</h1>
                            <p>Intial offering</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='DETAIL_Page'>
            <div className="container-fluid custam-container">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='Detail_text'>
                            <p>The young rapper born Javier Hardeman combines tuneful rhymes and an effortless flow about personal hardships and future aspirations. His relatable lyrics are what set him apart as a rising new star in an always crowded Atlanta rap scene. After a major label bidding war, Havi decided to sign with independent powerhouse, Commission Records - home to MadeinTYO, Lil Dicky, Derez De'Shon, Casey Veggies, and Maino. In addition, Havi has garnered co-signs from Atlanta icons Lil Baby, and T.I. Most recently Havi has been on a 26 date US tour with Lil Tjay, and recently released his debut project "Personal Problems" on February 7th, 2020 with features from Lil Baby and Derez De'Shon </p>
                            <button className='btn_detail'>DETAIL</button>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='Detail_img'>

                            <img src={DaylinXL3} className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='gallery'>
            <div className="container-fluid custam-container">
                <h1 className='Heding_Text_gallery'>#Big Havi<span>Gallery</span></h1>
            </div>
            <div className='gallery_silder'>
                <Slider {...settings}>
                    <div className='gall_img'>
                        <img src={silde1} className='img-fluid' />
                    </div>
                    <div className='gall_img'>
                        <img src={silde2} className='img-fluid' />
                    </div>
                    <div className='gall_img'>
                        <img src={silde3} className='img-fluid' />
                    </div>
                    <div className='gall_img'>
                        <img src={silde2} className='img-fluid' />
                    </div>
                    <div className='gall_img'>
                        <img src={silde3} className='img-fluid' />
                    </div>
                    <div className='gall_img'>
                        <img src={silde1} className='img-fluid' />
                    </div>
                </Slider>
            </div>
            <div className="container-fluid custam-container">
                <div className='row'>
                </div>
            </div>
        </section>

        <section className='John_Smith Bighavi'>
            <div className="container-fluid custam-container">
                <div className='row'>
                    <div className='Bnner_john_text'>
                        <h1>In a very unforgiving and fast moving musical context, Havi proves he can go toe to toe with the best of his rap contemporaries and likely stick around too.</h1>
                        <p> -Earmilk</p>
                    </div>
                </div>
            </div>
            {/* <div className='Durk_gallery'>
                <div className="container-fluid custam-container">
                    <h1 className='Heding_Text_gallery'>#BigHavi<span>Timeline</span></h1>
                </div>
                <div className='gallery_silder'>
                    <Slider {...settings}>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                    </Slider>
                </div>
            </div> */}
        </section>

        <section className='Financials'>
            <div className="container-fluid custam-container">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="financials_text">
                            <h1>#Bighavi<span>Timeline</span></h1>
                         <p>Talentshare holders will receive shares in an LLC owning the rights to 50% of the streaming income generated by "personal problems 3" across various platforms such as spotify. apple music, amazon music, youtube music, and more,</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                    <div className='Financials_div'>
                                <h1>Opportunity</h1>
                                <ul className='fine_text'>
                                    <li><h5>Funding goal</h5><p>$600,000</p></li>
                                    <li><h5>Minimum investment</h5><p>$90</p></li>
                                    <li><h5>Asset</h5><p>song</p></li>
                                    <li><h5>Income</h5><p>streaming</p></li>
                                    <li><h5>Instument</h5><p>equity</p></li>
                                    <li><h5>Opeaning date</h5><p>april 19, 2023</p></li>
                                    <li><h5>Expected closing date</h5><p>july 19, 2023</p></li>
                                </ul>
                            </div>

                        {/* <div className='Financials_div margin_div'>
                            <h1>Asset Specifications</h1>
                            <ul className='fine_text'>
                                <li><h5>Iaperiam</h5><p>$ 10</p></li>
                                <li><h5>nostrum exercitationem ullam</h5><p>180,000</p></li>
                                <li><h5>obtain some advantage</h5><p>$1,800,000</p></li>
                                <li><h5>voluptas assumenda est</h5><p>Exceed</p></li>
                                <li><h5>officia deserunt mollitia</h5><p>Exceed</p></li>
                                <li><h5>when nothing prevents</h5><p>Exceed</p></li>
                            </ul>
                        </div> */}

                    </div>
                </div>
            </div>
        </section>
    </div>

</div></div>
  )
}
