import React, { useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Slider from 'react-slick';
import DaylinXL3 from '../../../assent/img/LuMoody3.png'
import silde3 from '../../../assent/img/LuMoody2.png'
import silde2 from '../../../assent/img/LuMoody1.png'
import silde1 from '../../../assent/img/LuMoody22.png'
import line from '../../../assent/img/line.png'
import DaylinXL4 from '../../../assent/img/LuMoody4.png'

export default function LuhMoody() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    const settings = {
        dots: false,
        infinite: false,
        navs: true,
        speed: 2000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    navs: false,
                }
            }
            ,]
        // centerMode: true,
        // centerMode: true,
        //         centerMode: true,
        //   centerPadding: '60px',

    };
    return (
        <div> <div>
            <div>
                <section className='user-Artists'>
                    <div className="container-fluid custam-container">
                        <div className='back_btn'>
                            <Link to="/" className={(pathname === '/') ? 'active Link_page' : 'Link_page'} >Home</Link><span>{">"}</span><Link to="" className={(pathname === '/Artist/LuhMoody') ? 'active Link_page' : 'Link_page'} >Luh moody </Link>
                        </div>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='Artist_user_heding'>
                                    <h1>Trauman Bonds</h1>
                                    <h6>Luh Moody</h6>
                                    {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book. It has survived not only five centuries, but also
                                the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className='Artist_img'>
                                    <img src={DaylinXL4} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='total-Artists'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='Total_text'>
                                    <h1>$1,800,000</h1>
                                    <p>Inital offering marketing cap</p>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='Total_text'>
                                    <h1>$10</h1>
                                    <p>Inital offering share price</p>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='Total_text  last_text'>
                                    <h1>30/2023</h1>
                                    <p>Intial offering</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='DETAIL_Page'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='Detail_text'>
                                    <p>Luh Moody's debut album "Trauma Bonds" is a raw and emotive reflection of the artist's turbulent past, exploring themes of gun violence, substance abuse, family dysfunction, and emotional anguish. With a sonic balance of melodic croons and forceful drill raps, Moody showcases his versatile style and poignant lyricism. The album features collaborations with artists Munna Ike and Longe2k and was executive produced by Big Savo and Mezzy Beats. Despite the cumbersome subject matter, "Trauma Bonds" also contains hopeful undertones, signifying Moody's aspirations for success and a better future.</p>
                                    <button className='btn_detail'>DETAIL</button>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='Detail_img'>

                                    <img src={DaylinXL3} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='gallery'>
                    <div className="container-fluid custam-container">
                        <h1 className='Heding_Text_gallery'>#Luh Moody
                            <span>Gallery</span></h1>
                    </div>
                    <div className='gallery_silder'>
                        <Slider {...settings}>
                            <div className='gall_img'>
                                <img src={silde1} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde2} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde3} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde2} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde3} className='img-fluid' />
                            </div>
                            <div className='gall_img'>
                                <img src={silde1} className='img-fluid' />
                            </div>
                        </Slider>
                    </div>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                        </div>
                    </div>
                </section>

                <section className='John_Smith luh'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='Bnner_john_text'>
                                <h1>“Luh Moody's enthusiasm and love for his community is deeply represented in his music. His ability to output a high number of songs within a short recording session while still giving emotion driven performances is highly impressive.”</h1>
                                <p>- Joey Casias; Platinum Recording Engineer; Credits: Polo G and DaBaby
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Durk_gallery'>
                <div className="container-fluid custam-container">
                    <h1 className='Heding_Text_gallery'>#Luh Moody<span>Timeline</span></h1>
                </div>
                <div className='gallery_silder'>
                    <Slider {...settings}>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                        <div className='gall_img'>
                            <p>"Lorem ipsum dolor sit amet, consectetur
                                dolore magna aliqua. Ut enim ad minim
                                nostrud exercitation ullamco laboris nisi
                                ex ea commodo consequat. Duis aute
                                in reprehenderit</p>
                            <span>2002-05-15</span>
                            <img src={line} className='img-fluid' />
                        </div>
                    </Slider>
                </div>
            </div> */}
                </section>

                <section className='Financials'>
                    <div className="container-fluid custam-container">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="financials_text">
                                    <h1>#Luh Moody<span>Timeline</span></h1>
                                    <p>Talentshare holders will receive shares in an llc owning the rights to 30% the streaming income generated by luh moody next 15 song across various platforms</p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='Financials_div'>
                                    <h1>Opportunity</h1>
                                    <ul className='fine_text'>
                                        <li><h5>Funding goal</h5><p>$4000</p></li>
                                        <li><h5>Minimum investment</h5><p>$15</p></li>
                                        <li><h5>Asset</h5><p>15 song</p></li>
                                        <li><h5>Income</h5><p>streaming</p></li>
                                        <li><h5>Instument</h5><p>equity</p></li>
                                        <li><h5>Opeaning date</h5><p>april 17, 2023</p></li>
                                        <li><h5>Expected closing date</h5><p>july 17, 2023</p></li>
                                    </ul>
                                </div>

                                {/* <div className='Financials_div margin_div'>
                            <h1>Asset Specifications</h1>
                            <ul className='fine_text'>
                                <li><h5>Iaperiam</h5><p>$ 10</p></li>
                                <li><h5>nostrum exercitationem ullam</h5><p>180,000</p></li>
                                <li><h5>obtain some advantage</h5><p>$1,800,000</p></li>
                                <li><h5>voluptas assumenda est</h5><p>Exceed</p></li>
                                <li><h5>officia deserunt mollitia</h5><p>Exceed</p></li>
                                <li><h5>when nothing prevents</h5><p>Exceed</p></li>
                            </ul>
                        </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div></div>
    )
}
