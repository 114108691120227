import React, { useEffect, useLayoutEffect } from 'react'
import '../Artist/Artist.css'
import '../Homepage/Homepage.css'
import goup from '../../assent/img/g2.png'
import mt from '../../assent/img/mt.png'
import qrcode from '../../assent/img/qr-code.png'
import mobile from '../../assent/img/mobile.png'
import apstore from '../../assent/img/apstore.png'
import gpay from '../../assent/img/gpay.png'
import v1 from '../../assent/img/video1.png'
import v2 from '../../assent/img/video2.png'
import v3 from '../../assent/img/video3.png'
import user1 from '../../assent/img/user1.png'
import user2 from '../../assent/img/user2.png'
import user3 from '../../assent/img/user3.png'
import user4 from '../../assent/img/user4.png'
import user5 from '../../assent/img/user5.png'
import user6 from '../../assent/img/user6.png'
import user7 from '../../assent/img/user7.png'
import { Link, useLocation } from 'react-router-dom'

export default function Artist() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return (
        <div>
            <section className='Artist_section'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='Artist_heding'>
                                <h1>Exceed Artists</h1>
                                <p> The Exceed Platform is a marketplace that allows Fans to invest in the Talent they love. Unlike royalty sharing platforms, Exceed seeks to offer fans a broad range of investment opportunities from musical artists, influencers, creators, athletes and other talents who have impacted our lives. In addition, Exceed enables Fans to benefit beyond royalty-related income and extend it to live performance, physical sales, merchandise.
                                </p>
                                <div className='Link_btn'>
                                    <a href='#'><img src={gpay} className='img-fluid' /></a>
                                    <a href='#'><img src={apstore} className='img-fluid' /></a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            {/* <div className='Artist_img'>
                        <img src={goup} className='img-fluid' />
                        </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className='lildurk'>
                <div className="container-fluid custam-container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='lil_img'>
                                <img src={mt} className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='lil_text'>
                                <h1>lil durk (American rapper)</h1>
                                <p>Born on 19th October 1992, the American Rapper and Singer Durk
                                    Derrick Banks, also known as Lil Durk, is 30 years old as of 2022.</p>
                                <p>Durk Derrick Banks, known professionally as Lil Durk, is an American
                                    rapper, singer and songwriter. He is the lead member and founder
                                    of the collective and record label Only the Family</p>
                            </div>
                            <div className='video_section'>
                                <div className='video_text'>
                                    <h6>Video song</h6>
                                    <button className='btn'>View All</button>
                                </div>
                                <ul className='video_list'>
                                    <li> <div>
                                        <img src={v1} className='img-fluid' />
                                    </div>
                                        <div className='video_text_list'>
                                            <h5>Lil Durk - AHHH HA (Official Music Video)</h5>
                                            <p>YouTube · Lil Durk · 22-Feb-2022</p>
                                        </div>
                                    </li>
                                    <li> <div>
                                        <img src={v2} className='img-fluid' />
                                    </div>
                                        <div className='video_text_list'>
                                            <h5>Doodie Lo- Hip Hop DX (Official Music Video)</h5>
                                            <p>YouTube ·Doodie Lo · 10-May-2022</p>
                                        </div>
                                    </li>
                                    <li> <div>
                                        <img src={v3} className='img-fluid' />
                                    </div>
                                        <div className='video_text_list'>
                                            <h5>DaylinXL - effort lessly  (Official Music Video)</h5>
                                            <p>YouTube ·DaylinXL· 05-Feb-2022</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='Our-Artists'>
                <div className="container-fluid custam-container">
                    <div className='our_heding'>
                        <h1>Our Artists </h1>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/Artistuser'>
                                    <img src={user1} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Lil Durk </h5>
                                        <p className="card-text">“Lil Durk’s ascent to the top of the genre has been characterized by confidence and comfort within his signature sound. Durk,</p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/Doodie'>
                                    <img src={user4} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Doodie Lo</h5>
                                        <p className="card-text">Doodie Lo emits convincing terror whether he’s trading bars with Kodak Black or fellow OTF crew members. </p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/DaylinXL'><img src={user6} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">DaylinXL</h5>
                                        <p className="card-text">"DaylinXL is a true artistic gem, showcasing a rare talent for combining clever lyricism with beautifully melodic croons that captivate the listener.</p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/LuhMoody'> <img src={user3} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Luh Moddy</h5>
                                        <p className="card-text">“Luh Moody's enthusiasm and love for his community is deeply represented in his music. His ability to output a high number of songs within a short recording session while still giving emotion driven performances is highly impressive.”</p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/BigHavi'>      <img src={user5} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Big Havi</h5>
                                        <p className="card-text">In a very unforgiving and fast moving musical context, Havi proves he can go toe to toe with the best of his rap contemporaries and likely stick around too.
                                        </p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div class="card" >
                                <Link to='/Artist/AlexEnvy'>  <img src={user7} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Alex Envy</h5>
                                        <p className="card-text">“Through his elevated level of writing ability, Alex is able to convey feelings and emotions with authenticity that many other artists struggle to get across with the same effectiveness”</p>
                                        <a href="#" className="btn btn-primary">Explore Offerings</a>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
